import { Component, OnInit } from '@angular/core';
import { MapAirlineService } from 'src/app/services/map-airline.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-count-pending-map-airline',
  templateUrl: './count-pending-map-airline.component.html',
  styleUrls: ['./count-pending-map-airline.component.scss']
})
export class CountPendingMapAirlineComponent implements OnInit {

  count: Observable<number>;
  constructor(private mapDataService: MapAirlineService) { }

  ngOnInit() {
    this.count = this.mapDataService.pendingCount;
    this.mapDataService.countPendingMapAirlines()
      .subscribe();
  }

}
