import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MapAirlineService } from 'src/app/services/map-airline.service';
import { MapCountryService } from 'src/app/services/map-country.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private mapAirlineService: MapAirlineService, private mapCountryService: MapCountryService) { }

  ngOnInit() {
    if (this.route.snapshot.params.token) {
      this.authService.setToken(this.route.snapshot.params.token).subscribe(async user => {
        if (user) {
          await this.router.navigate(['/product/new-product'], {
            replaceUrl: true
          });

          // รอปรับโครงสร้าง module ให้ authen ก่อนที่จะโหลด
          this.mapAirlineService.countPendingMapAirlines().subscribe();
          this.mapCountryService.countPendingMapCountries().subscribe();

        }
      });
    }
  }

}
