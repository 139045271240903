import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
export class HttpService {
    constructor(http, authService) {
        this.http = http;
        this.authService = authService;
    }
    get(url, params) {
        let computeUrl = url;
        if (params) {
            computeUrl = url + '?' + this.convertQueryString(params);
        }
        const authorization = this.authService.getToken();
        return this.http.get(computeUrl, {
            headers: { authorization }
        });
    }
    post(url, body) {
        const computeUrl = url;
        return this.http.post(computeUrl, body, {
            headers: { authorization: this.authService.getToken(), 'content-type': 'application/json; charset=utf-8' }
        });
    }
    put(url, body) {
        const computeUrl = url;
        return this.http.put(computeUrl, body, {
            headers: { authorization: this.authService.getToken(), 'content-type': 'application/json; charset=utf-8' }
        });
    }
    convertQueryString(params) {
        return Object.keys(params).map((key) => {
            return key + '=' + encodeURIComponent(params[key] || '');
        }).join('&');
    }
}
HttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: HttpService, providedIn: "root" });
