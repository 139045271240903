import { Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
const ɵ0 = () => import("./product/product.module.ngfactory").then(mod => mod.ProductModuleNgFactory), ɵ1 = () => import("./map-data/map-data.module.ngfactory").then(mod => mod.MapDataModuleNgFactory), ɵ2 = () => import("./log/log.module.ngfactory").then(mod => mod.LogModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'product',
        pathMatch: 'full',
    },
    {
        path: 'product',
        loadChildren: ɵ0,
    },
    {
        path: 'map-data',
        loadChildren: ɵ1,
    },
    {
        path: 'auth/:token',
        component: AuthComponent
    },
    {
        path: 'log',
        loadChildren: ɵ2,
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
