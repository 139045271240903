import { Component, OnInit } from '@angular/core';
import { MapCountryService } from 'src/app/services/map-country.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-count-pending-map-country',
  templateUrl: './count-pending-map-country.component.html',
  styleUrls: ['./count-pending-map-country.component.scss']
})
export class CountPendingMapCountryComponent implements OnInit {

  count: Observable<number>;
  constructor(private mapDataService: MapCountryService) { }

  ngOnInit() {
    this.count = this.mapDataService.pendingCount;
    this.mapDataService.countPendingMapCountries()
      .subscribe();
  }

}
