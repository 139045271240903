/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./list-dialog.component";
var styles_ListDialogComponent = [i0.styles];
var RenderType_ListDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListDialogComponent, data: {} });
export { RenderType_ListDialogComponent as RenderType_ListDialogComponent };
function View_ListDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-list-item", [["class", "mat-list-item"]], [[8, "innerHTML", 1], [2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(603979776, 2, { _avatar: 0 }), i1.ɵqud(603979776, 3, { _icon: 0 })], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_2 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ListDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Total : ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(8, 704512, null, 0, i3.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ListDialogComponent_1)), i1.ɵdid(10, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["color", "accent"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).dialogRef.close(i1.ɵnov(_v, 15).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(15, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = _co.data.list; _ck(_v, 10, 0, currVal_3); var currVal_8 = "accent"; _ck(_v, 14, 0, currVal_8); var currVal_9 = true; _ck(_v, 15, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.data.title || "List"); _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.data.list == null) ? null : _co.data.list.length); _ck(_v, 6, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 14).disabled || null); var currVal_5 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 15).ariaLabel || null); var currVal_7 = i1.ɵnov(_v, 15).type; _ck(_v, 13, 0, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ListDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-dialog", [], null, null, null, View_ListDialogComponent_0, RenderType_ListDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.ListDialogComponent, [i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListDialogComponentNgFactory = i1.ɵccf("app-list-dialog", i10.ListDialogComponent, View_ListDialogComponent_Host_0, {}, {}, []);
export { ListDialogComponentNgFactory as ListDialogComponentNgFactory };
