

<!--The content below is only a placeholder and can be replaced.-->
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" opened="false" [fixedInViewport]="true">
      <mat-toolbar class="top-bar">
        <div>
          &nbsp;
        </div>
      </mat-toolbar>
    </mat-sidenav>
  <mat-sidenav-content>
      <mat-toolbar class="top-bar" color="primary">
        <div>
          &nbsp;
        </div>
        <div>Product Update</div>
        <div>
          <app-user-info></app-user-info>
        </div>
      </mat-toolbar>
      <div class="module-list">
          <a routerLink="/product/new-product" routerLinkActive="router-link-active">สินค้าใหม่</a>
          <a routerLink="/product/pdf" routerLinkActive="router-link-active">ไฟล์ PDF ที่แนบเอง</a>
          <a routerLink="/product/map-product" routerLinkActive="router-link-active">Map สินค้าเก่า-ใหม่</a>
          <a routerLink="/map-data/pending-country" routerLinkActive="router-link-active"><app-count-pending-map-country></app-count-pending-map-country></a>
          <a routerLink="/map-data/pending-airline" routerLinkActive="router-link-active"><app-count-pending-map-airline></app-count-pending-map-airline></a>
          <a routerLink="/product/agent-setting" routerLinkActive="router-link-active">เปิด/ปิด Update สินค้า</a>
          &nbsp; <a routerLink="./log" routerLinkActive="router-link-active">log</a>
        </div>
    <div class="module-container">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
  </mat-sidenav-container>
