import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./user.service";
export class AuthService {
    constructor(userService) {
        this.userService = userService;
        this.UserInfo = new BehaviorSubject(null);
        if (localStorage.getItem('token')) {
            // get userInfo
            // this.token.next(localStorage.getItem('token'));
        }
    }
    getToken() {
        return localStorage.getItem('token') || '';
    }
    setToken(token) {
        localStorage.setItem('token', token);
        return this.userService.getUserInfo(token);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.UserService)); }, token: AuthService, providedIn: "root" });
