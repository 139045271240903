import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

interface IData {
  text: string;
  danger: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IData, private dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  ngOnInit() {

  }

  onConfirm() {
    this.dialogRef.close(true);
  }

}
