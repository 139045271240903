import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { UserInfo } from '../models/user-info';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { MapAirlineService } from './map-airline.service';
import { MapCountryService } from './map-country.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly UserInfo = new BehaviorSubject<UserInfo>(null);

  constructor(private userService: UserService) {
    if (localStorage.getItem('token')) {
      // get userInfo
      // this.token.next(localStorage.getItem('token'));
    }
  }

  getToken(): string {
    return localStorage.getItem('token') || '';
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
    return this.userService.getUserInfo(token);
  }

}
