/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./auth.component";
import * as i3 from "@angular/router";
import * as i4 from "../../services/auth.service";
import * as i5 from "../../services/map-airline.service";
import * as i6 from "../../services/map-country.service";
var styles_AuthComponent = [i0.styles];
var RenderType_AuthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0E01\u0E33\u0E25\u0E31\u0E07\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 ...."]))], null, null); }
export function View_AuthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i1.ɵdid(1, 114688, null, 0, i2.AuthComponent, [i3.Router, i3.ActivatedRoute, i4.AuthService, i5.MapAirlineService, i6.MapCountryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthComponentNgFactory = i1.ɵccf("app-auth", i2.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
