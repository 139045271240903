/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./count-pending-map-country.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/badge";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "./count-pending-map-country.component";
import * as i7 from "../../services/map-country.service";
var styles_CountPendingMapCountryComponent = [i0.styles];
var RenderType_CountPendingMapCountryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountPendingMapCountryComponent, data: {} });
export { RenderType_CountPendingMapCountryComponent as RenderType_CountPendingMapCountryComponent };
function View_CountPendingMapCountryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "mat-badge"], ["matBadgeColor", "accent"], ["matBadgeOverlap", "false"], ["matBadgeSize", "small"]], [[2, "mat-badge-overlap", null], [2, "mat-badge-above", null], [2, "mat-badge-below", null], [2, "mat-badge-before", null], [2, "mat-badge-after", null], [2, "mat-badge-small", null], [2, "mat-badge-medium", null], [2, "mat-badge-large", null], [2, "mat-badge-hidden", null], [2, "mat-badge-disabled", null]], null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.MatBadge, [i1.NgZone, i1.ElementRef, i3.AriaDescriber, i1.Renderer2, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"], overlap: [1, "overlap"], content: [2, "content"], size: [3, "size"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_10 = "accent"; var currVal_11 = "false"; var currVal_12 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform(_co.count)), ""); var currVal_13 = "small"; _ck(_v, 1, 0, currVal_10, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).overlap; var currVal_1 = i1.ɵnov(_v, 1).isAbove(); var currVal_2 = !i1.ɵnov(_v, 1).isAbove(); var currVal_3 = !i1.ɵnov(_v, 1).isAfter(); var currVal_4 = i1.ɵnov(_v, 1).isAfter(); var currVal_5 = (i1.ɵnov(_v, 1).size === "small"); var currVal_6 = (i1.ɵnov(_v, 1).size === "medium"); var currVal_7 = (i1.ɵnov(_v, 1).size === "large"); var currVal_8 = (i1.ɵnov(_v, 1).hidden || !i1.ɵnov(_v, 1)._hasContent); var currVal_9 = i1.ɵnov(_v, 1).disabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_CountPendingMapCountryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28\u0E43\u0E2B\u0E21\u0E48 "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CountPendingMapCountryComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.count)) > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CountPendingMapCountryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-count-pending-map-country", [], null, null, null, View_CountPendingMapCountryComponent_0, RenderType_CountPendingMapCountryComponent)), i1.ɵdid(1, 114688, null, 0, i6.CountPendingMapCountryComponent, [i7.MapCountryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountPendingMapCountryComponentNgFactory = i1.ɵccf("app-count-pending-map-country", i6.CountPendingMapCountryComponent, View_CountPendingMapCountryComponent_Host_0, {}, {}, []);
export { CountPendingMapCountryComponentNgFactory as CountPendingMapCountryComponentNgFactory };
