import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  get<T>(url: string, params?: object): Observable<T> {
    let computeUrl = url;

    if (params) {
      computeUrl = url + '?' + this.convertQueryString(params);
    }

    const authorization = this.authService.getToken();

    return this.http.get<T>(
      computeUrl,
      {
        headers: { authorization }
      }
    );
  }

  post<T>(url: string, body: any): Observable<T> {
    const computeUrl = url;

    return this.http.post<T>(
      computeUrl,
      body,
      {
        headers: { authorization: this.authService.getToken(), 'content-type': 'application/json; charset=utf-8' }
      }
    );
  }

  put<T>(url: string, body: any): Observable<T> {
    const computeUrl = url;

    return this.http.put<T>(
      computeUrl,
      body,
      {
        headers: { authorization: this.authService.getToken(), 'content-type': 'application/json; charset=utf-8' }
      }
    );
  }

  private convertQueryString(params: object) {
    return Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key] || '');
    }).join('&');
  }
}
