import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDefaultModule } from './mat-default.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JsonViewerComponent } from './components/json-viewer/json-viewer.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { AuthComponent } from './components/auth/auth.component';
import { AirlineAutocompleteComponent } from './components/airline-autocomplete/airline-autocomplete.component';
import { CountryAutocompleteComponent } from './components/country-autocomplete/country-autocomplete.component';
import { ListDialogComponent } from './components/list-dialog/list-dialog.component';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { CountPendingMapAirlineComponent } from './components/count-pending-map-airline/count-pending-map-airline.component';
import { CountPendingMapCountryComponent } from './components/count-pending-map-country/count-pending-map-country.component';
import { LinkProductDetailComponent } from './components/link-product-detail/link-product-detail.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [
    JsonViewerComponent,
    UserInfoComponent,
    AuthComponent,
    AirlineAutocompleteComponent,
    CountryAutocompleteComponent,
    ListDialogComponent,
    KeepHtmlPipe,
    CountPendingMapAirlineComponent,
    CountPendingMapCountryComponent,
    LinkProductDetailComponent,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    MatDefaultModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    MatDefaultModule,
    FormsModule,
    ReactiveFormsModule,
    JsonViewerComponent,
    UserInfoComponent,
    AirlineAutocompleteComponent,
    CountryAutocompleteComponent,
    CountPendingMapAirlineComponent,
    CountPendingMapCountryComponent,
    LinkProductDetailComponent,
  ],
  entryComponents: [
    ListDialogComponent,
    ConfirmDialogComponent,
  ],
})
export class SharedComponentModule { }
