import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MapAirlineService {

  pendingCount = new BehaviorSubject<number>(0);

  constructor(private http: HttpService) {

  }

  decreasePendingCount(n: number) {
    const curr = this.pendingCount.getValue();
    this.pendingCount.next(curr - n);
  }

  countPendingMapAirlines(): Observable<number> {
    return this.http.get<number>(environment.apiScrape + '/api/PendingMapAirlines/count').pipe(
      tap(r => this.pendingCount.next(r)));
  }

  pendingMapAirlines(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiScrape + '/api/PendingMapAirlines');
  }

  saveMap(scrapeAirlineCode: string, tpxAirlineCode: string) {
    return this.http.post<any[]>(
      environment.apiScrape + '/api/PendingMapAirlines/SaveMap',
      {
        scrapeAirlineCode,
        tpxAirlineCode,
      });
  }

}
