import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
export class MapAirlineService {
    constructor(http) {
        this.http = http;
        this.pendingCount = new BehaviorSubject(0);
    }
    decreasePendingCount(n) {
        const curr = this.pendingCount.getValue();
        this.pendingCount.next(curr - n);
    }
    countPendingMapAirlines() {
        return this.http.get(environment.apiScrape + '/api/PendingMapAirlines/count').pipe(tap(r => this.pendingCount.next(r)));
    }
    pendingMapAirlines() {
        return this.http.get(environment.apiScrape + '/api/PendingMapAirlines');
    }
    saveMap(scrapeAirlineCode, tpxAirlineCode) {
        return this.http.post(environment.apiScrape + '/api/PendingMapAirlines/SaveMap', {
            scrapeAirlineCode,
            tpxAirlineCode,
        });
    }
}
MapAirlineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapAirlineService_Factory() { return new MapAirlineService(i0.ɵɵinject(i1.HttpService)); }, token: MapAirlineService, providedIn: "root" });
