import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserInfo } from '../models/user-info';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUserInfo(token): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${environment.apiScrape}/api/auth/${token}`);
  }
}
